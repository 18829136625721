html {
  overflow-y: auto;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.section {
  background-color: inherit;
}

a {
  color: #5E81AC;
  transition: all .2s;
}

a:hover {
  color: #434C5E;
}
